/*
    Ratio Testsite Entry
*/
function requireAll(r) {
    r.keys().forEach(r);
}
//Require SASS
require('../sass/style.scss');
//Require all TypeScript
requireAll(require.context('../ts/', true, /\.ts$/));
